import { forwardRef, useEffect, useState } from "react";
import __, { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import { LOCAL_STORAGE_KEYS, TWENTY_REDORDS_PER_PAGE, INVITE_USERS_TYPE } from "../../../constants/Constants";
import SessionCell from "./SessionCell";
import SignalR from "../../../services/signalR";
import JourneySessionCell from "./JourneySessionCell";
import DesktopNotification from "../../../services/desktopNotification";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import storage from "../../../services/storage";
import { getSessionDetails, putAssigneeChange } from "../../../actions/sessionDetailAction";
import { fetchInvitationURL, handleErrorMsgToastVisiblity } from "../../../shared/utility";
import { createMeetingUrl } from "../../../shared/utility";

let journeyCallUserJoinWaiting = null;
let agentURL = null;

const ScrollingList = forwardRef((props, ref) => {
    const {
        setCurrentActionType,
        isDiscardChanged,
        setIsDiscardModalOpen,
        sessionsList,
        fetchingSessions,
        sessionPageNo,
        fetchMoreSessions,
        canFetchMoreSession,
        localisedValues,
    } = props;
    const [height, setHeight] = useState(0); //Used static value as of now, will be setup dynamic once SCSS done with media query for landscape screen
    const [incomingJourneyCallSession, setIncomingJourneyCallSession] = useState([]);
    const [journeyInvitedSessionID, setJourneyInvitedSessionID] = useState('');
    const [normalInvitedSessionID, setNormalInvitedSessionID] = useState('');
    const [journeyInvitedSessionObject, setJourneyInvitedSessionObject] = useState('');

    const {sessionDetail} = useSelector((state) => state);
    const params = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const elmnt = document.getElementById("session_sidebar_body");
        setHeight(elmnt.offsetHeight);
    }, []);

    useEffect(() => {
        SignalR.incomingCallCallbackFunction(newIncomingSession);
        SignalR.dropCallCallbackFunction(dropCallFunction);
        SignalR.acknoledgeReceived(acknoledgeReceivedCallback);
    }, []);

    useEffect(() => {
        let inviteURL, featureFlagsData
        if (sessionDetail.getSessionDetailsData) {
            featureFlagsData = sessionDetail.getSessionDetailsData;
            inviteURL = fetchInvitationURL();
        }
        if (sessionDetail.getSessionDetailsData && !isEmpty(journeyInvitedSessionID) && journeyInvitedSessionObject) {
            agentURL = createMeetingUrl(inviteURL, featureFlagsData, INVITE_USERS_TYPE.AGENT, journeyInvitedSessionID);
            const guestlink = createMeetingUrl(inviteURL, featureFlagsData, INVITE_USERS_TYPE.AGENT, journeyInvitedSessionID);
            joinIncomingSessionCall(journeyInvitedSessionObject, guestlink);
        } else if (sessionDetail.getSessionDetailsData && !isEmpty(normalInvitedSessionID)) {
            agentURL = createMeetingUrl(inviteURL, featureFlagsData, INVITE_USERS_TYPE.AGENT);
            window.location.href = agentURL;
        }
    }, [sessionDetail.getSessionDetailsData]);

    useEffect(() => {
        if (sessionDetail.putAssigneeChangeData) {
            joinSession(journeyInvitedSessionID);
        }
        if (sessionDetail.assigneeChangeError) {
            console.log('assigned data Error in Journey session cell')
        }
    }, [sessionDetail.putAssigneeChangeData, sessionDetail.putAssigneeChangeError]);

    const loadMoreSessions = () => {
        fetchMoreSessions();
    };

    // new journey incoming call session handler function
    const newIncomingSession = (data) => {
        if (SignalR.incomingJourneyCallSession?.length > 0) {
            const index = SignalR.incomingJourneyCallSession.findIndex(call => call.sessionID == data.sessionID);
            if (index < 0) {
                SignalR.SetInterval(data);
                waitingTimeFunction(data);
                SignalR.isincomingCalls = true;
                DesktopNotification.handleNotification(data);
                const newArray = [...SignalR.incomingJourneyCallSession];
                newArray.unshift(data);
                setIncomingJourneyCallSession(newArray);
                SignalR.incomingJourneyCallSession.unshift(data)
            } else {
                waitingTimeFunction(SignalR.incomingJourneyCallSession[index]);
            }
        } else {
            SignalR.SetInterval(data);
            waitingTimeFunction(data);
            setIncomingJourneyCallSession([data]);
            SignalR.incomingJourneyCallSession = [data];
            SignalR.isincomingCalls = true;
            DesktopNotification.handleNotification(data);
        }
    };

    /* handle drop call event for the journey incoming call */
    const dropCallFunction = (data) => {
        if (SignalR.incomingJourneyCallSession) {
            const index = SignalR.incomingJourneyCallSession.findIndex(call => call.sessionID == data.sessionID);
            if (index >= 0) {
                const updatedCalls = [...SignalR.incomingJourneyCallSession.slice(0, index), ...SignalR.incomingJourneyCallSession.slice(index + 1)];
                setIncomingJourneyCallSession(updatedCalls);
                SignalR.incomingJourneyCallSession.splice(index)
                SignalR.isincomingCalls = false;
                DesktopNotification.handleNotification();
            }
        }
    };

    /* waiting time handling for the incoming call from the signalR */
    const waitingTimeFunction = (data) => {
        clearTimeout(data.waitingTime);
        data.waitingTime = setTimeout(() => {
            console.warn('remove call');
            clearTimeout(data.waitingTime);
            const index = SignalR.incomingJourneyCallSession.findIndex(call => call.sessionID == data.sessionID);
            if (index >= 0) {
                // Create a new array without the removed element
                const updatedCalls = [...SignalR.incomingJourneyCallSession.slice(0, index), ...SignalR.incomingJourneyCallSession.slice(index + 1)];
                // Update the state with the new array
                setIncomingJourneyCallSession(updatedCalls);
                SignalR.incomingJourneyCallSession.splice(index)
            }
        }, 30000);
    };

    const joinSession = (sessionId) => {
        if (__.isEmpty(params) && sessionId) {
            dispatch(getSessionDetails({ sessionId: sessionId}));
        }
    }

    const joinNormalSession = (sessionId) => {
        setNormalInvitedSessionID(sessionId);
        dispatch(getSessionDetails({ sessionId: sessionId}));
    }

    const joinIncomingSessionCall = (callData, guestlink) => {
        SignalR.JoinSession(callData, guestlink).then(function (result) {
            clearTimeout(journeyCallUserJoinWaiting);
            //start timer for 5 second
            journeyCallUserJoinWaiting = setTimeout(() => {
                handleErrorMsgToastVisiblity('User does not exist');
                if (SignalR.incomingJourneyCallSession) {
                    const index = SignalR.incomingJourneyCallSession.findIndex(call => call.sessionID == callData.sessionID);
                    if (index >= 0) {
                        SignalR.incomingJourneyCallSession.splice(index, 1);
                    }
                }
            }, 5000);
        })
            .catch(error => {
                console.error('Error joining session:', error);
                // Handle the error, e.g., show an error message to the user
            });
    };

    const joinJourneySession = (sessionId, sessionObject) => {
        // if journey meeting user try to invite from other user's meeting then set current user to default assignee 
        // if (!joinJourneySessionLoading) {
        // setJoinJourneySessionLoading(true)
        setJourneyInvitedSessionID(sessionId)
        setJourneyInvitedSessionObject(sessionObject)
        SignalR.answeredSessionID = sessionId; // set a variable for received a currunt tab call 
        SignalR.CallAnswered(sessionObject);//Send message as soon as Agent click on Answer to remove incomming calls from all other Agents to avoid assignee update issue
        if (storage.get(LOCAL_STORAGE_KEYS.UCID) != sessionObject.defaultAssigneeUCID) {
            const req = {
                sessionId: sessionId,
                userId: storage.get(LOCAL_STORAGE_KEYS.UCID)
            };
            dispatch(putAssigneeChange(req));
        } else {
            dispatch(getSessionDetails({ sessionId: sessionId }));
        }
        // }
    };

    /**
     * The function `openJoinCallWindow` redirects the user to a specified URL.
     */
    const openJoinCallWindow = () => {
        window.location.href = agentURL;
    };

    /**
     * The `acknoledgeReceivedCallback` function clears a timeout and opens a join call window.
     * @param data - The `data` parameter is the data received or acknowledged by the callback
     * function. It can be any type of data, such as a string, number, object, or array. The specific
     * data being received will depend on the context or purpose of the callback function.
     */
    const acknoledgeReceivedCallback = (data) => {
        clearTimeout(journeyCallUserJoinWaiting);
        openJoinCallWindow()
    };

    return (
        <div className="session-sidebar-body custom-scroll-div" id="session_sidebar_body">
            {sessionsList.length === 0 && !fetchingSessions && incomingJourneyCallSession.length === 0 &&
                <div className="session-list-empty">
                    <div className="session-list-empty-label">
                        {localisedValues["no_sessions"]}
                    </div>
                </div>
            }
            {sessionsList.length === 0 && fetchingSessions &&
                <div className="session-list-loader">
                    <div className="loader">
                        <span className="for-icon icon-loader spin"></span>
                    </div>
                </div>
            }
            <InfiniteScroll
                dataLength={sessionPageNo + TWENTY_REDORDS_PER_PAGE}
                next={loadMoreSessions}
                hasMore={canFetchMoreSession}
                scrollableTarget="session_sidebar_body"
            >
                <div className="session-list">
                    {incomingJourneyCallSession?.length > 0 && incomingJourneyCallSession.map((journeySession, index) => (
                        <JourneySessionCell journeySession={journeySession} key={Math.random()} joinJourneySession={joinJourneySession} localisedValues={localisedValues} />
                    ))}
                    {sessionsList.length > 0 && sessionsList.map((session) => (
                        <SessionCell ref={ref} session={session} key={session.sessionId} isDiscardChanged={isDiscardChanged} setIsDiscardModalOpen={setIsDiscardModalOpen} setCurrentActionType={setCurrentActionType} joinSession={joinNormalSession} localisedValues={localisedValues} />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    )
});

ScrollingList.propTypes = {
    setCurrentActionType: PropTypes.func,
    isDiscardChanged: PropTypes.bool,
    setIsDiscardModalOpen: PropTypes.func,
    sessionsList: PropTypes.array,
    fetchingSessions: PropTypes.bool,
    sessionPageNo: PropTypes.number,
    canFetchMoreSession: PropTypes.bool,
    localisedValues: PropTypes.object,
    fetchMoreSessions: PropTypes.func,
}

export default ScrollingList;